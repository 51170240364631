@import '../../styles/breakpoints.scss';

.Root {
  width: 100vw;
  height: 100vh;

  &.ZoomLevel400,
  &.ZoomLevel300 {
    min-height: 200vh;
  }
}

.Wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  max-width: 100vw;
  padding: 0;

  @media (min-width: $breakpoint-m) {
    overflow: hidden;
  }
}

.Desktop {
  display: none !important;
  @media (min-width: $breakpoint-m) {
    display: block !important;
  }
}

.Div100 {
  .ZoomLevel400 &,
  .ZoomLevel300 & {
    min-height: 200vh;
  }
}

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: black;
  color: white;
  font-size: 44px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  position: relative;

  width: 100%;
  height: 100%;

  background-image: url('/img/why-bg-placeholder--responsive.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: $breakpoint-m) {
    background-image: url('/img/why-bg-placeholder.webp');
  }

  // border: 1px dotted red;
}

.FadeOverlay {
  position: absolute;
  left: 0;
  bottom: -50vh;

  width: 100%;
  height: 50vh;

  max-width: 100vw;
  max-height: 100vh;

  background-color: var(--color-black-800);
  background: linear-gradient(
    0deg,
    rgba(24, 24, 24, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );

  z-index: 3;
  pointer-events: none;

  .ZoomLevel400 &,
  .ZoomLevel300 & {
    display: none;
  }
}

.Video {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: block;

  pointer-events: none;
  object-fit: cover;

  pointer-events: none;

  background-color: transparent;
}

.SceneContainer {
  position: relative;
  width: 100%;
  height: 100%;

  color: var(--color-white);
}

.ProgressContainer {
  position: absolute;
  bottom: 3.4rem;
  left: var(--spacing-l);
  width: calc(100% - 2 * var(--spacing-l));

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: $breakpoint-m) {
    bottom: 5.4rem;
    flex-wrap: nowrap;
  }
}

.RespButtonWrapper {
  display: flex;
  justify-content: space-between;

  height: 32px;
  width: 100%;

  margin-bottom: 2rem;
  @media (min-width: $breakpoint-m) {
    display: none;
  }
}

.ButtonContainer {
  height: 32px;
  @media (min-width: $breakpoint-m) {
    width: 32px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.1);
  }
}

.ButtonTextResp {
  display: inline-block;
  margin-right: 1rem;
  color: var(--color-white);
  font-size: var(--font-size-button-3);
}

.PlayButton {
  background-color: transparent;
  border: none;
  padding: 0;

  cursor: pointer;
}

.PlayIcon {
  width: 100%;
  height: auto;
}

.Progress {
  width: 100%;
  // max-width: 596px;
  max-width: 768px;

  display: flex;
  // border: 1px dotted green;
}

.ProgressLine {
  position: relative;

  height: 3px;
  min-height: 3px;
  width: auto;

  background-color: rgba(white, 0.2);

  @media (max-width: $breakpoint-m) {
    &:not(:first-of-type) {
      margin-left: 12px;
    }
  }

  @media (min-width: $breakpoint-m) {
    margin-left: 12px;

    &:last-of-type {
      margin-right: 12px;
    }
  }
}

.ProgressLineInner {
  @keyframes progress {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  &.Playing {
    animation-name: progress;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }

  &.Played,
  &.Paused {
    width: 100%;
  }

  height: 100%;
  width: 0;

  background-color: white;
}

.ProgressLineButton {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
  background-color: transparent;
  border: none;
  padding: none;

  cursor: pointer;
}

// intro scene

.IntroSceneContainer {
  position: relative;
  padding: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.IntroHeading {
  display: block;
  padding: 0 var(--spacing-l);

  font-size: var(--font-size-hero-title);
  color: var(--color-white);
  letter-spacing: 1px;

  text-align: center;
  text-transform: uppercase;

  line-height: 130%;
  margin-bottom: 3.2rem;

  @media (min-width: $breakpoint-m) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    margin-bottom: 4.8rem;
    line-height: 130%;
  }

  @media (max-width: 500px) {
    span {
      display: inline;
      white-space: pre-line;
    }
  }
}

.IntroHeadingRegular {
  font-weight: normal;
  letter-spacing: 1px;

  // border: 2px solid green;

  @media (min-width: $breakpoint-m) {
    display: block !important;
  }
}

.IntroHeadingBold {
  font-weight: 700;
  letter-spacing: 1px;

  &:before {
    content: ' ';
    display: inline;
  }

  @media (min-width: $breakpoint-m) {
    display: block !important;
    &:before {
      display: none;
    }
  }
}

// base scene

.BaseSceneContainer {
  position: relative;
  padding: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.BaseHeading {
  display: block;

  padding: 0 var(--spacing-l);
  font-size: var(--font-size-hero-title);
  color: var(--color-white);
  letter-spacing: 1px;

  text-align: left;
  text-transform: uppercase;
  line-height: 130%;

  @media (min-width: $breakpoint-m) {
    padding: 0;
    display: grid;
    line-height: 110%;
  }
}

.BaseHeadingRegular {
  font-weight: normal;
  letter-spacing: 1px;

  @media (min-width: $breakpoint-m) {
    display: block;

    grid-column-start: 3;
    grid-column-end: 14;
  }
}

.BaseHeadingBold {
  font-weight: 700;
  letter-spacing: 1px;

  &:before {
    content: ' ';
    display: inline;
  }

  @media (min-width: $breakpoint-m) {
    display: block;

    grid-column-start: 4;
    grid-column-end: 14;

    &:before {
      display: none;
    }
  }
}

.BasePreambleContainer {
  display: block;

  padding: var(--spacing-l);
  margin-bottom: 2rem;

  color: var(--color-white);

  text-align: left;

  @media (min-width: $breakpoint-m) {
    margin-top: 3.2rem;

    padding: 0;
    display: grid;
  }
}

.BasePreamble {
  font-size: var(--font-size-preamble);
  line-height: 158%;

  @media (min-width: $breakpoint-m) {
    display: block;

    grid-column-start: 4;
    grid-column-end: 10;
  }
}

// contact scene

.ContactSceneContainer {
  position: relative;
  padding: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ContactHeading {
  display: block;
  padding: 0 var(--spacing-l);

  font-size: var(--font-size-hero-title);
  color: var(--color-white);
  letter-spacing: 1px;
  line-height: 130%;

  text-align: center;
  text-transform: uppercase;

  margin-bottom: 3.2rem;

  @media (max-width: 500px) {
    span {
      display: inline;
      white-space: pre-line;
    }
  }

  @media (min-width: $breakpoint-m) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    margin-bottom: 4.8rem;
    line-height: 130%;
  }
}

.ContactHeadingRegular {
  font-weight: normal;
  letter-spacing: 1px;

  @media (min-width: $breakpoint-m) {
    display: block;
  }
}

.ContactHeadingBold {
  font-weight: 700;
  letter-spacing: 1px;

  &:before {
    content: ' ';
    display: inline;
  }

  @media (min-width: $breakpoint-m) {
    display: block;

    &:before {
      display: none;
    }
  }
}
