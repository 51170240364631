@import '../../styles/breakpoints.scss';
.Root {
  --gradient-image: url('/img/gradients/blue-purple--800p.webp');

  @media (min-width: $breakpoint-m) {
    --gradient-image: url('/img/gradients/blue-purple--1200p.webp');
  }
}

.Content {
  overflow: hidden;
  margin-top: -10vh;
  position: relative;
  z-index: 3;
  height: calc(100% + 15vh);
}

.InsightsWrapper {
  margin-top: 10rem;
  min-height: 100vh;

  @media (min-width: $breakpoint-m) {
    min-height: 192vh;
  }
}

.Title {
  position: relative;
  color: #fff;
  z-index: 1;
  line-height: 48px;
  // margin-bottom: 40px;
  letter-spacing: 1px;
  grid-row-gap: 0;
}

.TitleDesktop {
  display: none;
  position: relative;
  z-index: 1;
}

.TitleMobile {
  position: relative;
  z-index: 1;
  opacity: 1;
  transform: skew(13deg) translateY(30px);
  transition: opacity 1.5s ease, transform 1s ease;

  &.Visible {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}

.TitleContainer {
  grid-column-start: 2;
  grid-column-end: 6;
}

.TitleTop {
  font-size: var(--font-size-module-title);
  // grid-column-start: 2;
  // grid-column-end: 15;
  text-transform: uppercase;
  display: inline;
  font-weight: normal;

  opacity: 1;
  transform: skew(13deg) translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;

  .Visible & {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}
.TitleBottom {
  padding-left: 12px;
  font-size: var(--font-size-module-title);
  font-weight: bold;
  // grid-column-start: 2;
  // grid-column-end: 15;
  text-transform: uppercase;
  display: inline;

  opacity: 1;
  transform: skew(13deg) translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;
  transition-delay: 0.2s;

  .TitleMobile & {
    padding-left: 0;
  }

  .Visible & {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}

.ButtonWrapper {
  position: relative;
  z-index: 1;
  grid-column-start: 2;
  grid-column-end: 6;
  margin-bottom: 4rem;
}

.Text {
  position: relative;
  z-index: 1;
  font-size: var(--font-size-preamble);
  color: var(--color-grey-10);
  grid-column-start: 2;
  grid-column-end: 6;
  line-height: 1.65;
  margin-top: 3rem;
  margin-bottom: 4rem;

  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;
  transition-delay: 0.4s;

  .Visible & {
    opacity: 1;
    transform: translateY(0);
  }
}

.ImageWrapper {
  position: relative;
  height: 180px;
  grid-column-start: 2;
  grid-column-end: 6;
  margin-bottom: var(--module-bottom-margin);
}

.BlobRight {
  display: none;

  @media (min-width: $breakpoint-m) {
    display: block;
  }
}

.BlobLeft,
.BlobRight {
  position: absolute;
  top: -80vh;
  left: -64vh;
  height: 200vh;
  width: 200vw;
  background-image: var(--gradient-image);
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate3d(0, 0, 0);
  pointer-events: none;

  // opacity: 0;
  // transition: opacity 1.5s ease;
}
.FooterHr {
  position: relative;
  width: 100%;
  height: 1px;

  &:before {
    display: block;
    content: '';

    height: 1px;
    width: auto;

    opacity: 0.5;
    background-color: #6b6b6b;

    grid-column-start: 2;
    grid-column-end: 6;

    @media (min-width: $breakpoint-m) {
      grid-column-start: 2;
      grid-column-end: 14;
    }

    @media (min-width: $breakpoint-l) {
      grid-column-start: 2;
      grid-column-end: 14;
    }
  }
}

.BlobRight {
  height: 200vh;
  width: 200vw;
  top: 0;
  right: -170vw;
  left: unset;

  @media (min-width: $breakpoint-m) {
    top: -160px;
  }
}

@media (min-width: $breakpoint-m) {
  .TitleDesktop {
    display: grid;
  }
  .TitleMobile {
    display: none;
  }

  .TitleTop,
  .TaglineText {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
  }
  .ImageWrapper {
    height: 700px;
    transform: translateY(-70px);
    grid-column-start: 3;
    grid-column-end: 14;
  }

  .TitleBottom {
    display: block;
    grid-column-start: 4;
    grid-column-end: 14;
    padding-left: 0;
    letter-spacing: 1.5px;
  }

  .Text {
    grid-column-start: 4;
    grid-column-end: 13;
    line-height: 1.85;
  }

  .ButtonWrapper {
    grid-column-start: 4;
    grid-column-end: 13;
  }
  .Title,
  .Tagline {
    display: grid;
  }
  .TitleTop,
  .TaglineText {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
  }
  .TitleTop {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
    padding-left: 0;
  }
  .TitleBottom {
    display: block;
    grid-column-start: 4;
    grid-column-end: 14;
    padding-left: 0;
  }
  .Text {
    grid-column-start: 4;
    grid-column-end: 10;
    padding: 0;
  }
  .Title {
    line-height: 75px;
  }
}

@media (min-width: $breakpoint-ipad2) {
  .Title {
    grid-row-gap: 8px;
    line-height: 95px;
  }
}
