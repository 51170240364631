@import '../../styles/breakpoints.scss';
.Root {
  padding-top: var(--container-top-padding);

  &.HomePage {
    padding-top: 2rem;
  }

  position: relative;

  --gradient-image: url('/img/gradients/pink-purple--800p.webp');

  @media (min-width: $breakpoint-m) {
    --gradient-image: url('/img/gradients/pink-purple--1200p.webp');
  }
}

.PageSwitcher {
  position: relative;
}

.BlobLeft,
.BlobRight {
  position: absolute;
  top: -40vh;
  left: -10vh;
  height: 200vh;
  width: 200vw;
  background-image: var(--gradient-image);
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate3d(0, 0, 0);
  pointer-events: none;

  .HomePage & {
    top: -30vh;
  }

  // opacity: 0;
  // transition: opacity 1.5s ease;
}

.BlobRight {
  height: 200vh;
  width: 200vw;
  top: 0;
  right: -170vw;
  left: unset;
}

.Img {
  opacity: 0;
  transition: opacity 1.5s ease;

  &.Visible {
    opacity: 1;
  }
}

.Title {
  color: #fff;
  line-height: 48px;
  // margin-bottom: 40px;
  letter-spacing: 1px;
  grid-row-gap: 0;
}

.TitleDesktop {
  display: none;
}

.TitleMobile {
  opacity: 1;
  transform: skew(13deg) translateY(30px);
  transition: opacity 1.5s ease, transform 1s ease;
  position: relative;
  z-index: 1;

  &.Visible {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}

.TitleContainer {
  grid-column-start: 2;
  grid-column-end: 6;
}

.TitleTop {
  font-size: var(--font-size-hero-title);
  // grid-column-start: 2;
  // grid-column-end: 15;
  text-transform: uppercase;
  display: inline;
  font-weight: normal;

  .HomePage & {
    font-size: var(--font-size-module-title);
  }

  opacity: 1;
  transform: skew(13deg) translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;

  .Visible & {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}
.TitleBottom {
  padding-left: 12px;
  font-size: var(--font-size-hero-title);
  font-weight: bold;
  // grid-column-start: 2;
  // grid-column-end: 15;
  text-transform: uppercase;
  display: inline;

  .HomePage & {
    font-size: var(--font-size-module-title);
  }

  opacity: 1;
  transform: skew(13deg) translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;
  transition-delay: 0.2s;

  .TitleMobile & {
    padding-left: 0;
  }

  .Visible & {
    opacity: 1;
    transform: skew(0) translateY(0);
  }
}

.Text {
  font-size: var(--font-size-preamble);
  color: var(--color-grey-10);
  grid-column-start: 2;
  grid-column-end: 6;
  line-height: 1.65;
  margin-bottom: var(--module-bottom-margin);
  margin-top: 1rem;

  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1s ease;
  transition-delay: 0.4s;

  .Visible & {
    opacity: 1;
    transform: translateY(0);
  }
}

.Tagline {
  color: #fff;
  font-size: 1.2rem;
  font-weight: normal;
  letter-spacing: 0.26rem;
  text-transform: uppercase;
}

.Link {
  color: var(--color-white);
  font-family: var(--font-family-futura);
  font-size: var(--font-size-card-title);
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  gap: 16px;
  align-items: center;
  transition: transform 0.8s ease;
  width: max-content;

  &:hover {
    transform: translateX(0px);
  }

  &:before {
    content: '';
    width: 28px;
    height: 22px;
    background-image: url('/img/icon-arrow-right-white.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    display: inline-block;
    opacity: 1;
    transition: opacity 1s ease;
  }

  &:after {
    content: '';
    height: 2px;
    width: 0%;
    display: block;
    position: absolute;
    bottom: -6px;
    right: 0;
    left: unset;
    transition: opacity 1s ease, width 1s ease;
    opacity: 1;
    background-image: var(--gradient-purple-blue);
  }
  &:hover:after {
    width: 100%;
    left: 0;
    right: unset;
    opacity: 1;
  }
  &:hover:before {
    opacity: 1;
  }
}

.Links {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column: 2 / 6;
  transform: translateY(-12rem);
}

.Image {
  aspect-ratio: 725/474;
  position: relative;
  grid-row: 1 / -1;
  grid-column: 2 / 6;
  z-index: 1;
  transform: translateX(2rem);
  // filter: drop-shadow(33px 38px 100px rgba(0, 0, 0, 0.3))
  //   drop-shadow(-40px -42px 100px rgba(255, 255, 255, 0.05));

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.45) 0%,
      rgba(0, 0, 0, 0) 77.85%
    );
    z-index: 1;
  }
}

@media (min-width: $breakpoint-m) {
  .Tagline {
    font-size: 1.4rem;
  }
  .Image {
    grid-column: 5 / 14;
    transform: translateX(0);
    &:before {
      background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.45) 0%,
        rgba(0, 0, 0, 0) 77.85%
      );
    }
  }

  .Link {
    transform: translateX(-40px);

    &:before {
      opacity: 0;
    }
  }
  .Links {
    transform: translateY(0);
    padding-top: 5rem;
    grid-column: 3 / 14;
    grid-row: 1 / -1;
  }
  .TitleDesktop {
    display: grid;
  }
  .TitleMobile {
    display: none;
  }

  .TitleTop,
  .TaglineText {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
  }

  .TitleBottom {
    display: block;
    grid-column-start: 4;
    grid-column-end: 14;
    padding-left: 0;
    letter-spacing: 1.5px;
  }

  .Text {
    grid-column-start: 4;
    grid-column-end: 13;
    line-height: 1.85;
    margin-top: 3rem;

    .NoContent & {
      margin-top: 0;
      margin-bottom: 8rem;
    }
  }
}

@media (min-width: $breakpoint-ml) {
  .Tagline {
    margin-bottom: 1.6rem;
  }
  .Title,
  .Tagline {
    display: grid;
  }
  .TitleTop,
  .TaglineText {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
  }
  .TitleTop {
    display: block;
    grid-column-start: 3;
    grid-column-end: 14;
    padding-left: 0;
  }
  .TitleBottom {
    display: block;
    grid-column-start: 4;
    grid-column-end: 14;
    padding-left: 0;
  }
  .Text {
    grid-column-start: 4;
    grid-column-end: 10;
    padding: 0;
  }
  .Title {
    line-height: 75px;
  }
}

@media (min-width: $breakpoint-ipad2) {
  .Title {
    grid-row-gap: 8px;
    line-height: 95px;
  }
}
